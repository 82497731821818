import React from 'react'
import styled from 'styled-components'
import {
  Dialog, Heading, Alert, Pane, Text, Badge,
} from 'evergreen-ui'

import useInterval from '../../hooks/use-interval'
import useEnhancedState from '../../hooks/use-enhanced-state'
import useSetPath from '../../hooks/use-set-path'
import { useGlobalState, getGlobalState } from '../../hooks/use-global-state'
import sls from '../../utils/single-line-string'
import serverCall from '../../utils/server-call'

const orgName = process.env.REACT_APP_ORG_NAME
  || 'this organization'
const appName = process.env.REACT_APP_ORGANIZATION_APP_NAME
  || 'LeftDash'
const onboardingAlias = process.env.REACT_APP_ONBOARDING_ALIAS
  || 'community agreement'
const finalStepTitle = process.env.REACT_APP_ONBOARDING_FINAL_STEP_MESSAGE
    || 'Thanks, one more thing!'

const dashCodeAlias = process.env.REACT_APP_DASHCODE_ALIAS || 'DashCode'
const initialStepCountdown = Number(
  process.env.REACT_APP_ONBOARDING_STEP_COUNTDOWN || 0,
)

const StepContent = styled.div`
  font-family: "Segoe UI", Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #333;
`

const OnboardingDialog = () => {
  useSetPath('/onboarding')
  const { isMobile } = getGlobalState('deviceType')
  const [{
    onboardingSteps,
    ...user
  }, setUser] = useGlobalState('user')
  const [{
    currentStep,
    errorMessage,
    isSaving,
    stepCountdown,
  }, setState] = useEnhancedState({
    currentStep: 0,
    errorMessage: '',
    isSaving: false,
    stepCountdown: initialStepCountdown,
  })

  useInterval(() => {
    if (stepCountdown > 0) {
      setState({ stepCountdown: stepCountdown - 1 })
    }
  }, 1000)

  const handleIncrementStep = (increment) => {
    setState({
      stepCountdown: increment > 0 ? initialStepCountdown : stepCountdown,
      currentStep: currentStep + increment,
    })
  }

  const handleFinish = async () => {
    setState({ isSaving: true })
    try {
      await serverCall(`user/${user.id}`, {
        method: 'put',
        data: { isOnboarded: true },
      })
      setUser({ ...user, isOnboarded: true })
    } catch (err) {
      setState({
        isSaving: false,
        errorMessage: err.message,
      })
    }
  }

  let {
    title, subtitle, content, confirmLabel,
  } = onboardingSteps.find(({ step }) => step === currentStep) || {}

  if (currentStep === 0) {
    title = sls`Hi ${user.firstName}, welcome to ${appName}!
      Please take a moment to read the ${orgName} ${onboardingAlias}.`
  }

  const isLastStep = onboardingSteps.length === currentStep

  return (
    <Dialog
      isShown
      width={670}
      topOffset={40}
      hasHeader={false}
      containerProps={{
        ...(isMobile ? {
          style: {
            borderRadius: 0,
            minWidth: '100%',
            marginLeft: 0,
            marginRight: 0,
          },
        } : {}),
    }}
      contentContainerProps={{
        ...(isMobile ? {
          style: {
            padding: '5%',
          },
        } : {}),
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      confirmLabel={
        stepCountdown || (
          confirmLabel || isLastStep ? 'Finish!' : 'Next'
        )
      }
      isConfirmDisabled={stepCountdown > 0}
      isConfirmLoading={isSaving}
      onConfirm={
        !isLastStep
          ? () => handleIncrementStep(1)
          : handleFinish
      }
      hasCancel={currentStep > 0}
      cancelLabel="Go Back"
      onCancel={() => handleIncrementStep(-1)}>
      <>
        <Heading
          marginTop={20}
          size={isMobile ? 600 : 700}>
          {!isLastStep ? title : finalStepTitle}
        </Heading>
        <Heading
          marginTop={20}
          size={isLastStep ? 600 : isMobile ? 400 : 500}>
          {!isLastStep
            ? subtitle
            : (
              <>
                {'Your '}
                <Badge
                  height={28}
                  padding={6}
                  marginLeft={4}
                  marginRight={5}
                  fontSize={20}
                  color="purple">
                  {dashCodeAlias}
                </Badge>
                {' is '}
                <Badge
                  height={34}
                  padding={9}
                  marginLeft={5}
                  fontSize={28}
                  color="yellow">
                  {user.dashcode}
                </Badge>
              </>
            )}
        </Heading>
        {!isLastStep ? (
          /* eslint-disable react/no-danger */
          <StepContent dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <Pane paddingTop={20}>
            <Text size={500}>
              {sls`
                You can share this with your folks to use when they take actions
                with ${orgName} so you get credit! We'll also create some links
                for you to use that automatically use your ${dashCodeAlias}, and
                you'll be able to create custom links with it too!
              `}
            </Text>
          </Pane>
        )}
      </>

      {errorMessage && (
        <Alert
          marginTop={20}
          appearance="default"
          intent="danger"
          title={errorMessage} />
      )}
    </Dialog>
  )
}

export default OnboardingDialog
