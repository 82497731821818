import React, { useRef } from 'react'
import { isEmail, isMobilePhone } from 'validator'
import {
  Dialog, Heading, Alert, TextInput, TextInputField, Spinner, Button,
} from 'evergreen-ui'

import useSetPath from '../../hooks/use-set-path'
import useEnhancedState from '../../hooks/use-enhanced-state'
import { useGlobalState, getGlobalState } from '../../hooks/use-global-state'
import serverCall from '../../utils/server-call'

const orgName = process.env.REACT_APP_ORG_NAME || 'this organization'

const initialState = {
  contactMethod: {
    value: '',
    type: '',
    code: '',
  },
  phone: '',
  email: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  isSending: false,
  isSent: false,
  isValidated: false,
  needsMoreInfo: false,
  errorMessage: '',
}

const AuthDialog = () => {
  useSetPath('/login')
  const { isMobile } = getGlobalState('deviceType')
  const [/* user */, setUser] = useGlobalState('user')
  const inputRef = useRef(null)
  const [{
    contactMethod,
    firstName,
    lastName,
    phone,
    email,
    zipCode,
    isSending,
    isSent,
    isValidated,
    needsMoreInfo,
    errorMessage,
  }, setState] = useEnhancedState(initialState)

  const getPromptText = () => {
    if (needsMoreInfo) {
      return 'We need a bit more info to find you!'
    }
    if (isSent && !isValidated) {
      return `Enter the code you received via ${contactMethod.type === 'phone' ? 'text' : 'email'}!`
    }
    if (isSent && isValidated) {
      return 'Thanks! Give us a sec to grab your info...'
    }
    return 'Hi there! What is the email or cell phone number you use '
      + `when engaging with ${orgName}?`
  }

  const getUser = async () => {
    setState({
      errorMessage: '',
      isSending: true,
    })
    try {
      const user = await serverCall('/user', {
        query: {
          email: email || (
            contactMethod.type === 'email'
              ? contactMethod.value
              : undefined
          ),
          phone: phone || (
            contactMethod.type === 'phone'
              ? contactMethod.value
              : undefined
          ),
          firstName,
          lastName,
          zipCode,
          isActualLogin: true,
        },
      })

      setUser({ ...user, isAuthenticated: true, hasRestoredCache: true })
    } catch (err) {
      if (err.cause === 'NEED_MORE_INFO') {
        setState({
          phone: (contactMethod.type === 'phone' && contactMethod.value) || '',
          email: (contactMethod.type === 'email' && contactMethod.value) || '',
          needsMoreInfo: true,
          isSending: false,
        })
      } else {
        setState({
          errorMessage: err.message,
          isSending: false,
        })
      }
    }
  }

  const handleSetEmailOrPhone = ({ target: { value } }) => {
    setState({
      errorMessage: '',
      contactMethod: {
        value,
        type: isEmail(value)
          ? 'email'
          : isMobilePhone(value, 'en-US')
            ? 'phone'
            : null,
        code: '',
      },
    })
  }

  const handleSetInfo = ({ target: { name, value } }) => {
    setState({
      errorMessage: '',
      [name]: value,
    })
  }

  const handleRequestCode = async () => {
    setState({
      errorMessage: '',
      isSending: true,
    })

    try {
      await serverCall('/auth/request-code', {
        query: { contactMethod },
      })

      setState({
        errorMessage: '',
        isSent: true,
        isSending: false,
      })
    } catch (err) {
      setState({
        errorMessage: err.message,
        isSending: false,
      })
    } finally {
      if (inputRef.current) inputRef.current.focus()
    }
  }

  const handleGoBack = () => {
    if (needsMoreInfo) {
      /* eslint-disable no-alert */
      const shouldStartOver = window.confirm('Are you sure you want to start over?')
      if (shouldStartOver) setState(initialState)
    } else {
      setState({
        errorMessage: '',
        isSent: false,
      })
    }
  }

  const handleSetCode = ({ target: { value } }) => {
    setState({
      errorMessage: '',
      contactMethod: {
        ...contactMethod,
        code: (value || '').replace(/\D/g, ''),
      },
    })
  }

  const handleValidateCode = async (closeDialog) => {
    setState({
      errorMessage: '',
      isSending: true,
    })

    try {
      await serverCall('/auth/validate-code', {
        query: { contactMethod },
      })

      setState({
        errorMessage: '',
        isValidated: true,
        isSending: false,
      })

      await getUser(closeDialog)
    } catch (err) {
      setState({
        errorMessage: err.message,
        isSending: false,
      })
    }
  }

  const isConfirmDisabled = isSending || (
    needsMoreInfo
      ? !firstName || !lastName || zipCode.length !== 5 || !(email + phone)
      : !isSent
        ? !contactMethod.type
        : !contactMethod.code
  )

  return (
    <Dialog
      isShown
      overlayProps={{
        style: {
          bottom: -50,
        },
      }}
      containerProps={{
        ...(isMobile ? {
          style: {
            borderRadius: 0,
            minWidth: '100%',
            marginLeft: 0,
            marginRight: 0,
          },
        } : {}),
      }}
      contentContainerProps={{
        ...(isMobile ? {
          style: {
            padding: '5%',
          },
        } : {}),
      }}
      topOffset={isMobile ? 60 : 100}
      hasHeader={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      // TODO look at fixing this the right way
      footer={() => ( // eslint-disable-line
        <div style={{
          display: 'flex',
          borderTop: '3px solid #aaa',
          marginTop: -24,
          marginRight: -32,
          marginBottom: -16,
          paddingTop: 16,
          width: 560,
          maxWidth: '90vw',
        }}>
          {(isSent && !isSending) && (
            <Button
              marginLeft="auto"
              intent="danger"
              onClick={handleGoBack}>
              {!needsMoreInfo ? 'Go Back' : 'Start Over'}
            </Button>
          )}
          <Button
            marginLeft={(isSent && !isSending) ? 30 : 'auto'}
            marginRight={30}
            intent="none"
            appearance="primary"
            disabled={isConfirmDisabled || isSending}
            onClick={
              needsMoreInfo
                ? getUser
                : !isSent
                  ? handleRequestCode
                  : handleValidateCode
            }>
            {!isSent ? 'Next' : 'Finish'}
          </Button>
        </div>
      )}>
      <Heading
        size={isMobile ? 600 : 700}
        marginTop={24}
        marginBottom={isMobile ? 20 : 30}>
        {getPromptText()}
      </Heading>

      {(needsMoreInfo && errorMessage) && (
        <Alert
          style={{ marginTop: -20, marginBottom: 12 }}
          appearance="default"
          intent="danger"
          title={errorMessage} />
      )}

      {isSending ? (
        <Spinner marginX="auto" marginBottom={20} size={60} />
      ) : (!needsMoreInfo && !isValidated) ? (
        <>
          <TextInput
            ref={inputRef}
            size={isMobile ? 'medium' : 'large'}
            height={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            autoFocus
            disabled={isSending}
            placeholder={
              !isSent
                ? 'Enter your email or cell...'
                : 'Enter the code...'
            }
            value={
              !isSent
                ? contactMethod.value
                : contactMethod.code
            }
            onChange={
              !isSent
                ? handleSetEmailOrPhone
                : handleSetCode
            }
            onKeyPress={({ key }) => {
              if (key === 'Enter' && !isConfirmDisabled) {
                !isSent
                  ? handleRequestCode()
                  : handleValidateCode()
              }
            }}
            isInvalid={!!(
              !isSent
                ? contactMethod.value.length >= 10 && !contactMethod.type
                : contactMethod.code && contactMethod.code.length < 6
            )} />
          {!isSent && (
            <p>
              By signing in on this page, I affirm my intent to join the Working Families
              Party as a member or to continue my membership, and to receive periodic
              updates from WFP.
            </p>
          )}
        </>
      ) : needsMoreInfo && (
        <>
          <TextInputField
            autoFocus
            size={isMobile ? 'medium' : 'large'}
            inputHeight={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            disabled={isSending}
            required
            name="firstName"
            label="First Name"
            placeholder="Enter your first name..."
            value={firstName}
            onChange={handleSetInfo}
            isInvalid={false} />
          <TextInputField
            size={isMobile ? 'medium' : 'large'}
            inputHeight={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            disabled={isSending}
            required
            name="lastName"
            label="Last Name"
            placeholder="Enter your last name..."
            value={lastName}
            onChange={handleSetInfo}
            isInvalid={false} />
          <TextInputField
            size={isMobile ? 'medium' : 'large'}
            inputHeight={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            disabled={isSending}
            required
            name="zipCode"
            label="Residence Zip"
            placeholder="Enter your zipcode..."
            value={zipCode}
            onChange={handleSetInfo}
            isInvalid={false} />
          <TextInputField
            size={isMobile ? 'medium' : 'large'}
            inputHeight={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            disabled={isSending || contactMethod.type === 'email'}
            name="email"
            label="Primary Email"
            placeholder="Enter your primary email..."
            value={email}
            onChange={handleSetInfo}
            isInvalid={false} />
          <TextInputField
            size={isMobile ? 'medium' : 'large'}
            inputHeight={isMobile ? 60 : 50}
            fontSize={isMobile ? 19 : undefined}
            width="100%"
            marginBottom={20}
            autoFocus
            disabled={isSending || contactMethod.type === 'phone'}
            name="phone"
            label="Cell Phone"
            placeholder="Enter your cell phone..."
            value={phone}
            onChange={handleSetInfo}
            isInvalid={false} />
        </>
      )}

      {!needsMoreInfo && (errorMessage ? (
        <Alert
          style={{ marginBottom: 12 }}
          appearance="default"
          intent="danger"
          title={errorMessage} />
      ) : !isSent && (
        contactMethod.value.length >= 10 || contactMethod.type
      ) && (
        <Alert
          style={{ marginBottom: 12 }}
          appearance="default"
          intent={!contactMethod.type ? 'warning' : 'success'}
          title={
            !contactMethod.type
              ? 'Please enter a valid cell phone or email'
              : `You've entered a valid ${contactMethod.type}!`
          } />
      ))}
    </Dialog>
  )
}

export default AuthDialog
