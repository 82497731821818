const Input = {
  appearances: {
    default: {
      borderWidth: 2,
      _focus: {
        borderColor: 'colors.brand2',
        borderBottomWidth: 3,
        paddingBottom: 0,
        boxShadow: '0 3px 6px -2px #777',
        transition: 'border-color 500ms',
      },
    },
  },
  sizes: {
    large: {
      fontSize: '18px',
    },
    medium: {
      fontSize: '16px',
    },
  },
}

export default Input
