import { defaultTheme } from 'evergreen-ui'

const getPrimaryTheme = defaultTheme.components.Button.appearances.primary
const primarySuccess = getPrimaryTheme(defaultTheme, { intent: 'success' })
const primaryDanger = getPrimaryTheme(defaultTheme, { intent: 'danger' })

const Button = {
  ...defaultTheme.components.Button,
  appearances: {
    ...defaultTheme.components.Button.appearances,
    default: {
      fontSize: 18,
      height: 40,
    },
    primary: (theme, { intent }) => {
      switch (intent) {
        case 'action':
          return {
            ...primarySuccess,
            fontSize: 18,
            height: 44,
            backgroundColor: 'colors.brand3',
            borderColor: 'colors.brand3',
            color: 'colors.brand2',
            _disabled: {
              ...primarySuccess._disabled,
              backgroundColor: '#999',
              borderColor: '#999',
            },
            _hover: {
              ...primarySuccess._hover,
              backgroundColor: 'colors.brand3',
              borderColor: 'colors.brand2',
              boxShadow: '0 0 3px #777',
            },
            _active: {
              ...primarySuccess._active,
              backgroundColor: 'colors.brand3',
              borderColor: 'colors.brand3',
            },
            _focus: {
              ...primarySuccess._focus,
              backgroundColor: 'colors.brand3',
              borderColor: 'colors.brand3',
            },
          }
        case 'success':
          return {
            ...primarySuccess,
            fontSize: 18,
            height: 40,
            _disabled: {
              ...primarySuccess._disabled,
              backgroundColor: '#999',
              borderColor: '#999',
            },
            _hover: {
              ...primarySuccess._hover,
              boxShadow: '0 3px 6px -2px #777',
            },
            _active: primarySuccess._active,
            _focus: primarySuccess._focus,
          }
        case 'danger':
          return {
            ...primaryDanger,
            fontSize: 18,
            height: 40,
            _disabled: {
              ...primaryDanger._disabled,
              backgroundColor: '#999',
              borderColor: '#999',
            },
            _hover: {
              ...primaryDanger._hover,
              boxShadow: '0 0 6px #777',
            },
            _active: primaryDanger._active,
            _focus: primaryDanger._focus,
          }
        default:
          return {
            fontSize: 18,
            height: 40,
            backgroundColor: 'colors.brand2',
            borderColor: 'colors.brand2',
            color: '#fefefe',
            _disabled: {
              ...primarySuccess._disabled,
              backgroundColor: '#999',
              borderColor: '#999',
            },
            _hover: {
              ...primarySuccess._hover,
              borderColor: 'colors.brand1',
              backgroundColor: 'colors.brand2',
              boxShadow: '0 3px 6px -2px #777',
            },
            _active: {
              ...primarySuccess._active,
              backgroundColor: 'colors.brand2',
              borderColor: 'colors.brand2',
            },
            _focus: {
              ...primarySuccess._focus,
              backgroundColor: 'colors.brand2',
              borderColor: 'colors.brand1',
            },
          }
      }
    },
  },
}

export default Button
