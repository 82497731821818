import React from 'react'
import {
  Pane,
  Heading,
  Button,
  TickIcon,
  EditIcon,
  defaultTheme,
} from 'evergreen-ui'

const { colors, shadows } = defaultTheme

const CardTitle = ({
  title, isEditable, isEditing, handleEdit, handleSave,
}) => (
  <Pane
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    flexDirection="row"
    width="100%"
    minHeight={46}
    paddingTop={10}
    paddingBottom={10}
    paddingLeft={20}
    paddingRight={20}
    boxShadow={shadows[2]}
    background="colors.gray800">
    <Heading size={400} fontWeight={700} color={colors.gray200}>
      {title}
    </Heading>
    {isEditable && (
      <Button
        margin={0}
        marginRight={-12}
        marginTop={-3}
        marginBottom={-3}
        iconBefore={isEditing ? TickIcon : EditIcon}
        intent={isEditing ? 'success' : 'none'}
        onClick={isEditing ? handleSave : handleEdit}>
        {isEditing ? 'Save' : 'Edit'}
      </Button>
    )}
  </Pane>
)

export default CardTitle
