const Heading = {
  baseStyle: {
    color: 'colors.blue800',
  },
  sizes: {
    800: {
      lineHeight: 'lineHeights.5',
    },
    700: {
      lineHeight: 'lineHeights.5',
    },
    600: {
      color: 'colors.gray800',
    },
    500: {
      color: 'colors.gray800',
    },
  },
}

export default Heading
