import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { toaster } from 'evergreen-ui'

import defaultFavicon from '../../img/default-favicon.ico'
import serverCall from '../../utils/server-call'

const orgName = process.env.REACT_APP_ORGANIZATION_APP_NAME || 'LeftDash'
const favicon = process.env.REACT_APP_FAVICON_URL || defaultFavicon

const Head = () => {
  const [csrfToken, setCsrfToken] = useState('')

  useEffect(() => {
    let retries = 0
    const getCsrfToken = async () => {
      try {
        const token = await serverCall('/')
        setCsrfToken(token)
      } catch (err) {
        if (retries < 5) {
          retries += 1
          setTimeout(() => setCsrfToken(), 500)
        } else {
          toaster.danger('There has been an problem connecting to the server, please wait a bit and try refreshing the page.', {
            duration: 300, // 5 min
          })

          setTimeout(() => {
            window.location.reload()
          }, 300000)
        }
      }
    }

    getCsrfToken()
  }, [])

  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="csrf-token" content={csrfToken} />
      <link rel="icon" href={favicon} />
      <title>{orgName}</title>
    </Helmet>
  )
}

export default Head
