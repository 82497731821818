import React from 'react'
import {
  Pane,
} from 'evergreen-ui'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'
import AppHeader from './AppHeader'
import AuthDialog from './AuthDialog'
import OnboardingDialog from './OnboardingDialog'

import ActivistDash from '../activist/ActivistDash'
import useFetchGlobalInterval from '../../hooks/use-fetch-global-interval'
import { getGlobalState } from '../../hooks/use-global-state'
import useDeviceType from '../../hooks/use-device-type'

function App() {
  const user = getGlobalState('user')
  useFetchGlobalInterval()
  useDeviceType()

  return (
    <Router>
      <Pane
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        height="100vh"
        width="100%"
        background="brand2">
        <AppHeader />
        <ErrorBoundary>
          {!user.isAuthenticated ? (
            <AuthDialog />
          ) : !user.isOnboarded ? (
            <OnboardingDialog />
          ) : (
            <Pane
              width="100%"
              height="calc(100vh - 60px)">
              <Routes>
                <Route path="/*" element={<ActivistDash />} />
              </Routes>
            </Pane>
          )}
        </ErrorBoundary>
      </Pane>
    </Router>
  )
}

export default App
