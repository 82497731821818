import React, { useState, useRef } from 'react'
import {
  Pane,
  Card,
  Avatar,
  Heading,
  Badge,
  Spinner,
  toaster,
  defaultTheme,
} from 'evergreen-ui'
import CardTitle from '../core/CardTitle'

import ContactPreferences from './ContactPreferences'
import ActivistLadder from './ActivistLadder'
import ActivistLinks from './ActivistLinks'
import ActivistRecruits from './ActivistRecruits'
import ActivistImpacts from './ActivistImpacts'

import useSetPath from '../../hooks/use-set-path'
import { getGlobalState, useGlobalState } from '../../hooks/use-global-state'
import imgurUpload from '../../utils/imgur-upload'
import serverCall from '../../utils/server-call'
import UserBadges from './UserBadges'

const { colors } = defaultTheme

const dashCodeAlias = process.env.REACT_APP_DASHCODE_ALIAS || 'DashCode'

function ActivistDash() {
  useSetPath('/')
  const imageInputRef = useRef(null)
  const { isDesktop, isMobile } = getGlobalState('deviceType')
  const [user, setUser] = useGlobalState('user')
  const {
    fullName,
    firstName,
    lastName,
    pictureUrl,
    dashcode,
  } = user
  const ladders = getGlobalState('ladders')
  const [isUploadingImg, setIsUploadingImg] = useState(false)

  const handleUploadImage = async ({ target: { files } }) => {
    try {
      setIsUploadingImg(true)
      const newPictureUrl = await imgurUpload(files[0])
      if (!newPictureUrl) throw new Error('No picture URL..')

      setUser({ ...user, pictureUrl: newPictureUrl })
      await serverCall(`/user/${user.id}`, {
        method: 'put',
        data: { pictureUrl: newPictureUrl },
        query: { skipEaUpdate: true },
      })
    } catch (err) {
      console.error(err) // eslint-disable-line
      toaster.danger('Problem uploading image...')
    } finally {
      setIsUploadingImg(false)
    }
  }

  return (
    <Pane
      display="flex"
      flexWrap="wrap"
      height="100%"
      padding={isMobile ? 8 : 20}
      overflowY="auto">
      <Pane
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        alignContent="flex-start"
        minWidth={isDesktop ? '30%' : '100%'}
        maxWidth={isDesktop ? '30%' : '100%'}
        style={!isMobile ? {
          alignSelf: 'flex-start',
          top: 0,
        } : undefined}>
        <Card
          display="flex"
          alignItems="center"
          flexDirection="column"
          elevation={2}
          margin={isMobile ? 8 : 20}
          marginBottom="0"
          padding={20}
          width="100%"
          height="auto"
          background={colors.gray50}
          style={{ borderRadius: '5px 5px 0 0' }}>
          <button
            // TODO: verify this works as type button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
            disabled={isUploadingImg}
            onClick={() => imageInputRef?.current?.click()}>
            {!isUploadingImg ? (
              <Avatar
                src={pictureUrl}
                name={`${firstName} ${lastName}`}
                border={`3px solid ${colors.brand2}`}
                marginBottom={10}
                boxShadow="2px 4px 8px rgba(75,75,75,.35)"
                size={130} />
            ) : (
              <div style={{
                border: `3px solid ${colors.brand2}`,
                height: 124,
                width: 124,
                background: '#ddd',
                boxShadow: '2px 4px 8px rgba(75,75,75,.35)',
                borderRadius: 100,
                marginBottom: 10,
              }}>
                <Spinner marginTop={42} marginLeft={42} active />
              </div>
            )}
            <input
              ref={imageInputRef}
              style={{ display: 'none' }}
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              onChange={handleUploadImage} />
          </button>

          <Heading size={600}>
            {fullName}
          </Heading>

          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            marginTop={10}>
            <Badge
              height={24}
              padding={4}
              marginRight={10}
              fontSize={18}
              color="purple">
              {dashCodeAlias}
            </Badge>
            <Badge
              border="2px solid rgb(205, 162, 41, .75)"
              boxShadow="1px 1px 4px rgba(100, 100, 100, .25)"
              height={32}
              padding={6}
              fontSize={24}
              color="yellow">
              {dashcode}
            </Badge>
          </Pane>
        </Card>

        <UserBadges />

        <ContactPreferences />
      </Pane>

      <Pane
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        alignContent="flex-start"
        marginLeft={isMobile ? 0 : '10%'}
        width={isMobile ? '100%' : 560}>

        {ladders.map((ladder) => (
          <ActivistLadder key={ladder.id} {...ladder} />
        ))}

        <Card
          display="flex"
          alignItems="center"
          flexDirection="column"
          elevation={2}
          margin={isMobile ? 8 : 20}
          width="100%"
          height="auto"
          background={colors.gray50}
          style={{ borderRadius: '5px 5px 0 0' }}>
          <CardTitle title="Recruiting" />

          <ActivistLinks />

          {user.recruits.length > 0
            && <ActivistRecruits />}
        </Card>

        <ActivistImpacts />

      </Pane>
    </Pane>
  )
}

export default ActivistDash
