import React, { useState, useEffect } from 'react'
import {
  Pane,
  Heading,
  Popover,
  Position,
  Menu,
  IconButton,
  MenuIcon,
  LogOutIcon,
  Spinner,
  toaster,
} from 'evergreen-ui'

import { useGlobalState } from '../../hooks/use-global-state'
import serverCall from '../../utils/server-call'

const orgLogoUrl = process.env.REACT_APP_ORG_LOGO_URL
const orgName = process.env.REACT_APP_ORG_NAME || 'LeftDash'

const AppHeader = () => {
  const [user, setUser] = useGlobalState('user')
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  useEffect(() => {
    setIsLoggingOut(false)
  }, [user.id])

  const handleLogOut = async () => {
    setIsLoggingOut(true)
    try {
      await serverCall('/auth/log-out')
      localStorage.clear()
      setUser({})
    } catch (err) {
      toaster.danger('There was a problem logging out, please try again!')
      setIsLoggingOut(false)
    }
  }

  return (
    <Pane
      display="flex"
      height={60}
      width="100%"
      padding={15}
      margin={0}
      background="brand1">
      {orgLogoUrl ? (
        <img
          height={30}
          src={orgLogoUrl}
          alt={orgName}
          border="0" />
      ) : (
        <Heading color="white" size={900}>
          {orgName}
        </Heading>
      )}
      {user.isAuthenticated && (
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={(
            <Menu>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item
                  icon={!isLoggingOut ? LogOutIcon : Spinner}
                  onSelect={handleLogOut}>
                  {!isLoggingOut ? 'Log Out' : 'Logging Out...'}
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}>
          <Pane
            padding={2}
            marginTop={-6}
            marginLeft="auto"
            boxShadow="1px 1px 4px rgba(75,75,75,.6)"
            width={44}
            height={44}
            borderRadius={25}
            background="colors.brand2">
            <IconButton
              icon={<MenuIcon color="colors.brand1" />}
              width={40}
              height={40}
              borderRadius={25}
              appearance="minimal" />
          </Pane>
        </Popover>
      )}
    </Pane>
  )
}

export default AppHeader
