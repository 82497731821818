import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  TextInput,
  toaster,
} from 'evergreen-ui'
import { useGlobalState } from '../../hooks/use-global-state'
import serverCall from '../../utils/server-call'

function ActivistGoal(props) {
  const { goal } = props
  const [activistGoal, setActivistGoal] = useState(goal)
  const [isEditing, setIsEditing] = useState(goal.title.length === 0)
  const [user, setUser] = useGlobalState('user')

  const getUpdatedGoals = (updatedGoal = null) => user.goals.map((_goal) => {
    if (!updatedGoal && activistGoal.id === _goal.id) {
      return activistGoal
    }
    if (updatedGoal && activistGoal.id === _goal.id) {
      return updatedGoal
    }

    return _goal
  })

  const handleSaveGoal = () => {
    try {
      serverCall(`/goal/${goal.id}`, {
        method: 'PATCH',
        data: { title: activistGoal.title },
      })

      setUser({ ...user, goals: [...getUpdatedGoals()] })
    } catch (err) {
      toaster.danger(err.message)
    }

    setIsEditing(false)
  }

  const handleDeleteGoal = () => {
    const newGoals = user.goals.filter((_goal) => _goal.id !== activistGoal.id)
    setUser({ ...user, goals: newGoals })

    serverCall(`/goal/${goal.id}`, {
      method: 'DELETE',
    })
  }

  const handleToggleCompleted = async (_ev) => {
    setActivistGoal({ ...activistGoal, isCompleted: _ev.target.checked })
    try {
      const updatedGoal = await serverCall(`/goal/${goal.id}`, {
        method: 'PATCH',
        data: { is_completed: _ev.target.checked },
      })

      setUser({ ...user, goals: [...getUpdatedGoals(updatedGoal)] })
    } catch (err) {
      toaster.danger(err.message)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '22px' }}>
      <Checkbox
        name="goalIsCompleted"
        checked={goal.title.length === 0 ? false : activistGoal.isCompleted}
        paddingRight={6}
        disabled={goal.title.length === 0}
        onChange={(e) => handleToggleCompleted(e)} />
      {/* eslint-disable-next-line react/jsx-boolean-value */}
      <TextInput
        name="goalTitle"
        value={activistGoal.title}
        disabled={!isEditing}
        onChange={(e) => setActivistGoal({ ...activistGoal, title: e.target.value })} />
      <div display="flex">
        {isEditing ? (
          <Button
            marginLeft="auto"
            intent="success"
            height="medium"
            width="80px"
            marginX={8}
            onClick={() => handleSaveGoal()}
            appearance="primary">
            Save
          </Button>
        ) : (
          <Button
            marginLeft="auto"
            intent="success"
            height="medium"
            width="80px"
            marginX={8}
            onClick={() => setIsEditing(!isEditing)}
            appearance="primary">
            Edit
          </Button>
        )}
        <Button
          marginLeft="auto"
          intent="danger"
          height="medium"
          marginX={8}
          onClick={() => handleDeleteGoal()}
          appearance="primary">
          Delete
        </Button>
      </div>
    </div>
  )
}

export default ActivistGoal
