import { useEffect } from 'react'
import { cloneDeep } from 'lodash'

/* eslint-disable no-console */

const useLocalStateCache = ({
  module, state, setState, keysToRemove,
}) => {
  module = module ? `app-state-${module}` : ''

  keysToRemove = [
    ...keysToRemove || [],
    'errorMessage',
    'isFetching',
    'isSaving',
  ]

  useEffect(() => {
    if (module) {
      try {
        const cachedState = localStorage.getItem(module)
        if (cachedState) {
          setState(JSON.parse(cachedState))
        }
      } catch (err) { console.error(err) }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (module) {
      const clonedState = cloneDeep(state)

      if (keysToRemove && keysToRemove.length) {
        keysToRemove.forEach((key) => delete clonedState[key])
      }

      try {
        localStorage.setItem(module, JSON.stringify(clonedState))
      } catch (err) { console.error(err) }
    }
  })
}

export default useLocalStateCache
