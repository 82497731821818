import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useSetPath = (path) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(path)
  }, []) // eslint-disable-line
}

export default useSetPath
