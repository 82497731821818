import { createGlobalState } from 'react-hooks-global-state'

const initialState = {
  deviceType: {},
  user: {
    isAuthenticated: false,
  },
  ladders: [],
}

const globalState = createGlobalState(initialState)

export const { useGlobalState, getGlobalState } = globalState

// export const getGlobalState = (key) => {
//   if (key) return globalState.getGlobalState(key)
//   return Object.keys(initialState).reduce((thisKey, state) => ({
//     ...state,
//     [thisKey]: globalState.getGlobalState(thisKey),
//   }), {})
// }
