import React from 'react'
import { Heading, defaultTheme } from 'evergreen-ui'
import { getGlobalState } from '../../hooks/use-global-state'

const { isMobile } = getGlobalState('deviceType')
const { colors } = defaultTheme

function ActivistResult(props) {
  const { result } = props

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '6px',
      paddingBottom: '20px',
    }}>
      <Heading
        id="shortUrl"
        fontSize={isMobile ? 15 : 17}
        color={colors.gray700}
        display="flex"
        marginRight={12}>
        {result.impactType}
      </Heading>
      <span style={{ fontSize: '16px' }}>{result.impactValue}</span>
    </div>
  )
}

export default ActivistResult
