/*
  https://github.com/segmentio/evergreen/blob/29f9c41ff15527dac0892330b8032ccd585b1412/src/themes/default/tokens/typography.js
*/

const fontSizes = [
  '12px', '14px', '16px', '18px', '20px', '26px', '32px', '38px',
]

fontSizes.heading = '18px'
fontSizes.body = '16px'
fontSizes.caption = '12px'

export default {
  fontSizes,
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
}
