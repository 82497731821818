import React, { useState } from 'react'
import { format as phoneFormat } from 'phone-fns'
import { orderBy, isEqual } from 'lodash'
import { phone as phoneValidator } from 'phone'
import { isEmail } from 'validator'
import {
  Pane,
  Card,
  Text,
  Dialog,
  Heading,
  Tablist,
  Tab,
  Button,
  TextInput,
  Select,
  Checkbox,
  HomeIcon,
  PhoneIcon,
  EnvelopeIcon,
  AddIcon,
  toaster,
  defaultTheme,
} from 'evergreen-ui'

import useEnhancedState from '../../hooks/use-enhanced-state'
import usStates from '../../utils/us-states'
import serverCall from '../../utils/server-call'
import CardTitle from '../core/CardTitle'
import { getGlobalState, useGlobalState } from '../../hooks/use-global-state'

const { colors } = defaultTheme

const ContactItem = ({ Icon, value }) => {
  const { isMobile } = getGlobalState('deviceType')
  return (
    <Pane
      display="flex"
      flexShrink={isMobile ? 0 : 1}
      minWidth={isMobile ? '100%' : 'auto'}
      alignItems="center"
      margin={18}
      paddingLeft={isMobile ? 20 : '10%'}>
      <Icon
        color={colors.gray600}
        size={24}
        marginRight={15} />
      <Text>
        {value}
      </Text>
    </Pane>
  )
}

const EditContactDialog = ({ onClose }) => {
  const { isMobile } = getGlobalState('deviceType')
  const [user, setUser] = useGlobalState('user')
  const [selectedTab, setSelectedTab] = useState(0)
  const [data, setData] = useEnhancedState({
    addresses: orderBy(
      user.addresses.filter((item) => !item.isBad),
      ['isPreferred'],
      ['desc'],
    ),
    phones: orderBy(
      user.phones.filter((item) => !item.isBad),
      ['isPreferred'],
      ['desc'],
    ),
    emails: orderBy(
      user.emails.filter((item) => !item.isBad),
      ['isPreferred'],
      ['desc'],
    ),
  })
  const [isSaving, setIsSaving] = useState(false)
  const tabs = ['Address', 'Phone', 'Email']

  const handleInputChange = ({ target: { id, name, value } }) => {
    const [dataType, key] = name.split('.')

    if (key === 'phone') {
      value = (value || '').replace(/\D/g, '')
      if (value[0] === '1') value = value.substring(1)
      if (value.length >= 10) value = value.substring(0, 10)
      const { phoneNumber } = phoneValidator(value)
      if (phoneNumber) {
        value = phoneNumber.replace('+1', '')
      }
    }

    if (typeof value === 'boolean') value = !value
    if (['true', 'false'].includes(value)) value = !(value !== 'false')

    setData({
      [dataType]: data[dataType].map((item) => (
        String(item.id) === id
          ? {
            ...item,
            [key]: value,
            isPreferred: key === 'isBad' && value ? false : item.isPreferred,
          }
          : item
      )),
    })
  }

  const handlePreferredChange = ({ target: { id, name } }) => {
    const dataType = name
    const thisItem = data[dataType].find((item) => String(item.id) === id)

    if (!thisItem.isPreferred) {
      setData({
        [dataType]: data[dataType].map((item) => ({
          ...item,
          isPreferred: String(item.id) === id,
        })),
      })
    }
  }

  const handleAddItem = ({ target: { name: dataType } }) => {
    const itemSchemas = {
      addresses: {
        id: `new-${Date.now()}`,
        address: '',
        city: '',
        state: '',
        zip: '',
        type: 'home',
        isPreferred: !data.addresses.length,
        doNotMail: false,
        isBad: false,
        userId: user.id,
      },
      emails: {
        id: `new-${Date.now()}`,
        email: '',
        type: 'personal',
        isPreferred: !data.emails.length,
        doNotEmail: false,
        isBad: false,
        userId: user.id,
      },
      phones: {
        id: `new-${Date.now()}`,
        phone: '',
        type: null,
        isPreferred: !data.phones.length,
        doNotCall: false,
        doNotText: false,
        isBad: false,
        userId: user.id,
      },
    }

    setData({ [dataType]: [...data[dataType], itemSchemas[dataType]] })
  }

  const handleSave = async (close) => {
    setIsSaving(true)
    setUser({
      ...user,
      ...data,
    })

    try {
      await serverCall(`/user/${user.id}`, {
        method: 'put',
        data,
      })
      close()
    } catch (err) {
      toaster.danger(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  const handleClose = (close) => {
    close()
    setData(user)
  }

  const saveDisabled = {
    addresses: !!data.addresses.find((a) => (
      !a.address || !a.city || !a.state || !a.zip || !a.type
      || !(a.zip.length === 5 || a.zip.length === 10)
    )),
    phones: !!data.phones.find((p) => {
      const { isValid } = phoneValidator(p.phone || '')
      return !p.phone || !p.type || !isValid
    }),
    emails: !!data.emails.find((e) => (
      !isEmail(e.email) || !e.type
    )),
    noChange: isEqual(
      data,
      [...user.emails, ...user.phones, ...user.addresses],
    ),
  }

  return (
    <Dialog
      isShown
      overlayProps={{
        style: {
          bottom: -50,
        },
      }}
      containerProps={{
        ...(isMobile ? {
          style: {
            borderRadius: 0,
            minWidth: '100%',
            marginLeft: 0,
            marginRight: 0,
          },
        } : {}),
      }}
      contentContainerProps={{
        ...(isMobile ? {
          style: {
            padding: '5%',
          },
        } : {}),
      }}
      topOffset={isMobile ? 60 : 100}
      hasHeader={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onCloseComplete={onClose}
      // TODO: make this a component later?
      footer={({ close }) => ( //eslint-disable-line
        <div style={{
          display: 'flex',
          borderTop: '3px solid #aaa',
          marginTop: -24,
          marginRight: -32,
          marginBottom: -16,
          paddingTop: 16,
          width: 560,
          maxWidth: '90vw',
        }}>
          <Button
            marginLeft="auto"
            intent="danger"
            appearance="primary"
            disabled={isSaving}
            onClick={() => handleClose(close)}>
            Cancel
          </Button>
          <Button
            marginLeft={30}
            marginRight={30}
            intent="success"
            appearance="primary"
            isLoading={isSaving}
            disabled={Object.values(saveDisabled).find(Boolean)}
            onClick={() => handleSave(close)}>
            Save
          </Button>
        </div>
      )}>
      <Heading
        size={isMobile ? 500 : 600}
        marginTop={10}
        marginBottom={isMobile ? 10 : 15}>
        Edit Your Contact Preferences
      </Heading>

      <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            id={tab}
            width="30%"
            onSelect={() => setSelectedTab(index)}
            isSelected={index === selectedTab}
            aria-controls={`panel-${tab}`}>
            {tab}
          </Tab>
        ))}
      </Tablist>
      {selectedTab === 0 ? (
        <>
          {data.addresses.map(({
            id, address, address2, city, state, zip, type,
            isPreferred, isBad,
          }) => (
            <div
              key={id}
              style={{
                marginBottom: 20,
                paddingBottom: 20,
                borderBottom: '2px solid #aaa',
              }}>
              <TextInput
                size="large"
                id={id}
                placeholder="Address Line 1"
                name="addresses.address"
                value={address}
                onChange={handleInputChange} />
              <TextInput
                size="large"
                id={id}
                placeholder="Address Line 2"
                name="addresses.address2"
                value={address2}
                onChange={handleInputChange} />
              <TextInput
                size="large"
                id={id}
                placeholder="City"
                name="addresses.city"
                value={city}
                onChange={handleInputChange} />
              <div style={{ display: 'flex' }}>
                <Select
                  id={id}
                  name="addresses.state"
                  maxWidth={80}
                  size="large"
                  onChange={handleInputChange}>
                  <option value="" selected={!state} /> {/*eslint-disable-line*/}
                  {usStates.map(({ value }) => (
                    <option value={value} label={value} selected={state === value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <TextInput
                  size="large"
                  width={200}
                  id={id}
                  placeholder="Zip"
                  name="addresses.zip"
                  value={zip}
                  onChange={handleInputChange} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontSize={14} fontWeight={600} marginRight={8}>
                  Type
                </Text>
                <Select
                  id={id}
                  name="addresses.type"
                  maxWidth={90}
                  marginRight={24}
                  onChange={handleInputChange}>
                  <option value="home" selected={type === 'home'}>
                    Home
                  </option>
                  <option value="mailing" selected={type === 'mailing'}>
                    Mailing
                  </option>
                </Select>
                <Checkbox
                  label="Preferred"
                  id={id}
                  name="addresses"
                  disabled={isBad}
                  value={isPreferred}
                  checked={isPreferred}
                  onChange={handlePreferredChange} />
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: 30,
              }}>
                {/* <Checkbox
                  label="Do Not Mail"
                  marginRight={30}
                  id={id}
                  name="addresses.doNotMail"
                  value={doNotMail}
                  checked={doNotMail}
                  onChange={handleInputChange} /> */}
                {!String(id).includes('new') && (
                  <Checkbox
                    label="Bad / Moved"
                    id={id}
                    name="addresses.isBad"
                    value={isBad}
                    checked={isBad}
                    onChange={handleInputChange} />
                )}
              </div>
            </div>
          ))}
          <Button
            iconBefore={<AddIcon size={14} />}
            appearance="primary"
            size="small"
            height={34}
            marginTop={16}
            marginBottom={20}
            marginLeft="12%"
            fontSize={16}
            intent="none"
            name="addresses"
            disabled={saveDisabled.addresses || data.addresses.length === 2}
            onClick={handleAddItem}>
            Add Address
          </Button>
        </>
      ) : selectedTab === 1 ? (
        <>
          { data.phones.map(({
            id, phone, type, isPreferred, isBad,
          }) => (
            <div
              key={id}
              style={{
                marginBottom: 20,
                paddingBottom: 20,
                borderBottom: '2px solid #aaa',
              }}>
              <TextInput
                size="large"
                id={id}
                name="phones.phone"
                value={phone}
                onChange={handleInputChange} />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontSize={14} fontWeight={600} marginRight={8}>
                  Type
                </Text>
                <Select
                  id={id}
                  name="phones.type"
                  maxWidth={110}
                  marginRight={24}
                  onChange={handleInputChange}>
                  <option value="" selected={!type} /> {/* eslint-disable-line */}
                  <option value="cell" selected={type === 'cell'}>
                    Cell
                  </option>
                  <option value="landline" selected={type === 'landline'}>
                    Landline
                  </option>
                </Select>
                <Checkbox
                  label="Preferred"
                  id={id}
                  name="phones"
                  value={isPreferred}
                  checked={isPreferred}
                  disabled={isBad}
                  onChange={handlePreferredChange} />
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: 30,
              }}>
                {/* <Checkbox
                  label="Do Not Call"
                  marginRight={30}
                  id={id}
                  name="phones.doNotCall"
                  value={doNotCall}
                  checked={doNotCall}
                  onChange={handleInputChange} />
                {type === 'cell' && (
                  <Checkbox
                    label="Do Not Text"
                    marginRight={30}
                    id={id}
                    name="phones.doNotText"
                    value={doNotText}
                    checked={doNotText}
                    onChange={handleInputChange} />
                )} */}
                {!String(id).includes('new') && (
                  <Checkbox
                    label="Bad Number"
                    id={id}
                    name="phones.isBad"
                    value={isBad}
                    checked={isBad}
                    onChange={handleInputChange} />
                )}
              </div>
            </div>
          ))}
          <Button
            iconBefore={<AddIcon size={14} />}
            appearance="primary"
            size="small"
            height={34}
            marginTop={10}
            marginBottom={20}
            marginLeft="12%"
            fontSize={16}
            intent="none"
            name="phones"
            disabled={saveDisabled.phones}
            onClick={handleAddItem}>
            Add Phone
          </Button>
        </>
      ) : selectedTab === 2 && (
        <>
          {data.emails.map(({
            id, email, type, isPreferred, isBad,
          }) => (
            <div
              key={id}
              style={{
                marginBottom: 20,
                paddingBottom: 20,
                borderBottom: '2px solid #aaa',
              }}>
              <TextInput
                size="large"
                id={id}
                name="emails.email"
                value={email}
                onChange={handleInputChange} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontSize={14} fontWeight={600} marginRight={8}>
                  Type
                </Text>
                <Select
                  id={id}
                  name="emails.type"
                  maxWidth={110}
                  marginRight={24}
                  onChange={handleInputChange}>
                  <option value="personal" selected={type === 'personal'}>
                    Personal
                  </option>
                  <option value="work" selected={type === 'work'}>
                    Work
                  </option>
                </Select>
                <Checkbox
                  label="Preferred"
                  id={id}
                  name="emails"
                  value={isPreferred}
                  checked={isPreferred}
                  disabled={isBad}
                  onChange={handlePreferredChange} />
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: 30,
              }}>
                {/* <Checkbox
                  label="Do Not Email"
                  marginRight={30}
                  id={id}
                  name="emails.doNotEmail"
                  value={doNotEmail}
                  checked={doNotEmail}
                  onChange={handleInputChange} /> */}
                {!String(id).includes('new') && (
                  <Checkbox
                    label="Bad Email"
                    id={id}
                    name="emails.isBad"
                    value={isBad}
                    checked={isBad}
                    onChange={handleInputChange} />
                )}
              </div>
            </div>
          ))}

          <Button
            iconBefore={<AddIcon size={14} />}
            appearance="primary"
            size="small"
            height={34}
            marginTop={16}
            marginBottom={20}
            marginLeft="12%"
            fontSize={16}
            intent="none"
            name="emails"
            disabled={saveDisabled.emails}
            onClick={handleAddItem}>
            Add Email
          </Button>
        </>
      )}
    </Dialog>
  )
}

const ContactPreferences = () => {
  const { isMobile } = getGlobalState('deviceType')
  const {
    emails,
    phones,
    addresses,
  } = getGlobalState('user')
  const [isEditing, setIsEditing] = useState(false)

  const preferredAddress = orderBy(
    addresses.filter((item) => !item.isBad),
    ['isPreferred', 'createdAt'],
    ['desc', 'asc'],
  )[0]

  let preferredPhone = orderBy(
    phones.filter((item) => !item.isBad),
    ['isPreferred', 'createdAt'],
    ['desc', 'asc'],
  )[0]?.phone

  let preferredEmail = orderBy(
    emails.filter((item) => !item.isBad),
    ['isPreferred', 'createdAt'],
    ['desc', 'asc'],
  )[0]?.email

  const handleEdit = () => {
    setIsEditing(true)
  }

  const preferredContact = {
    address: (
      <Text>
        {preferredAddress?.address}
        <br />
        {preferredAddress?.address2 && (
          <>
            {preferredAddress?.address2}
            <br />
          </>
        )}
        {preferredAddress?.city ? ` ${preferredAddress?.city}, ` : ''}
        {preferredAddress?.state}
        {preferredAddress?.zip}
      </Text>
    ),
    email: preferredEmail,
    phone: phoneFormat('(NNN) NNN-NNNN', preferredPhone),
  }

  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      width="100%"
      height="auto"
      background="colors.gray50">
      <CardTitle
        title="Contact Preferences"
        isEditable
        handleEdit={handleEdit} />

      {isEditing && (
        <EditContactDialog onClose={() => setIsEditing(false)} />
      )}

      <Pane width="100%">
        <ContactItem Icon={HomeIcon} value={preferredContact.address} />
        <ContactItem Icon={PhoneIcon} value={preferredContact.phone} />
        <ContactItem Icon={EnvelopeIcon} value={preferredContact.email} />
      </Pane>
    </Card>
  )
}

export default ContactPreferences
