import { useState } from 'react'
import logger from '../utils/set-state-logger'
import useLocalStateCache from './use-local-state-cache'

const useEnhancedState = (initialState, module) => {
  const [state, setState] = useState(initialState)
  let setStateWithMerging = (newState) => setState({ ...state, ...newState })

  if (!module) module = window.location.pathname.substring(1)

  if (process.env.NODE_ENV !== 'production') {
    setStateWithMerging = logger(setStateWithMerging, state, module)
  }

  useLocalStateCache({ module, state, setState: setStateWithMerging })

  return [state, setStateWithMerging]
}

export default useEnhancedState
