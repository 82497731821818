import { useEffect, useCallback, useRef } from 'react'
import ifVisible from 'ifvisible.js'
import { isEqual } from 'lodash'
import { toaster } from 'evergreen-ui'

import { useGlobalState } from './use-global-state'
import useLocalStateCache from './use-local-state-cache'
import useInterval from './use-interval'
import serverCall from '../utils/server-call'

const useFetchUserInterval = () => {
  const [user, setUser] = useGlobalState('user')
  const [ladders, setLadders] = useGlobalState('ladders')

  /* fetch user data on initial page render and every 5 min.
     this also acts as an implicit auth session check */

  useLocalStateCache({ module: 'user', state: user, setState: setUser })
  useLocalStateCache({ module: 'ladders', state: ladders, setState: setLadders })

  const userRef = useRef(user)
  if (!isEqual(userRef.current, user)) userRef.current = user

  const fetchData = useCallback(async () => {
    try {
      const fetchUser = async () => {
        setUser({
          ...userRef.current,
          ...await serverCall(`/user/${user.id}`),
        })
      }
      const fetchLadders = async () => {
        setLadders(
          await serverCall('/ladder'),
        )
      }
      await Promise.all([
        fetchUser(),
        fetchLadders(),
      ])
    } catch (err) {
      if (err.message.includes('token')) {
        await serverCall('/auth/log-out')
        localStorage.clear()
        setUser({})
        toaster.notify('Your session has expired, please re-authenticate', {
          duration: 300, // 5 min
        })
      } else {
        if (process.env.NODE_ENV === 'development') {
          /* eslint-disable no-console */
          console.error(err)
        }
        toaster.warning('There has been a temporary problem connecting to the server.', {
          duration: 10,
        })
      }
    }
  }, [user.id, setUser, setLadders])

  useEffect(() => {
    if (user.id && user.isAuthenticated) {
      fetchData()
      /* re-fetching after 13s to grab any
         recruits pulled in after logging in, which relies on an async
         api call that could take a bit longer to run */
      setTimeout(() => {
        fetchData()
      }, 13000)
    }
  }, [fetchData, user.id, user.isAuthenticated])

  useInterval(() => {
    const tabIsActive = ifVisible.now()

    if (tabIsActive && user.id && user.isAuthenticated) {
      fetchData()
    }
  }, 300000)
}

export default useFetchUserInterval
