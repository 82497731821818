const clientId = process.env.REACT_APP_IMGUR_CLIENT_ID

export default async (file) => {
  try {
    const url = 'https://api.imgur.com/3/image'
    const data = new FormData()
    data.append('image', file)

    const res = await fetch(url, {
      method: 'post',
      headers: {
        Authorization: `Client-ID ${clientId}`,
      },
      body: data,
    })

    const resBody = await res.json()

    if (!resBody?.success) throw new Error(resBody)

    return resBody?.data?.link
  } catch (err) {
    console.error(err)
    throw err
  }
}
