import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'

import { ThemeProvider } from 'evergreen-ui'

import reportWebVitals from './reportWebVitals'
import Head from './modules/core/Head'
import App from './modules/core/App'

import theme from './theme'

import 'normalize.css'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <>
    <Head />
    <ThemeProvider value={theme}>
      <App />
    </ThemeProvider>
  </>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
