import React from 'react'
import {
  Button,
  Card,
  defaultTheme,
  Heading,
  Pane,
  toaster,
} from 'evergreen-ui'
import { getGlobalState, useGlobalState } from '../../hooks/use-global-state'
import CardTitle from '../core/CardTitle'
import ActivistGoal from './ActivistGoal'
import ActivistResult from './ActivistResult'
import serverCall from '../../utils/server-call'

const { isMobile } = getGlobalState('deviceType')
const { colors } = defaultTheme

function ActivistImpacts() {
  const [user, setUser] = useGlobalState('user')

  const handleAddNewGoal = async () => {
    try {
      const updatedGoal = await serverCall('/goal', {
        method: 'POST',
        data: {
          title: '',
          is_completed: false,
        },
      })

      setUser({ ...user, goals: [updatedGoal, ...user.goals] })
    } catch (err) {
      toaster.danger(err.message)
    }
  }

  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      minWidth={isMobile ? '96%' : 560}
      height="auto"
      background="colors.gray50">
      <CardTitle title="Activist Impacts" />
      <Pane width="100%" padding={20} paddingBottom={0} display="flex">
        <Heading
          minWidth={!isMobile ? 200 : '100%'}
          marginBottom={isMobile ? 15 : 0}
          fontSize={17}
          fontWeight={700}
          color={colors.gray800}>
          Goals
        </Heading>
        <Button
          marginLeft="auto"
          intent="success"
          height="medium"
          onClick={() => handleAddNewGoal()}
          // disabled={isSaving}
          appearance="primary">
          Add Goal
        </Button>
      </Pane>
      <Pane
        width="100%"
        padding={20}>
        {user.goals?.length > 0 && (
          user.goals?.map((goal) => (
            <ActivistGoal goal={goal} key={goal.id} />
          ))
        )}
      </Pane>
      {user.impacts.length > 0
      && (
        <Pane width="100%">
          <Pane width="100%" display="flex" paddingLeft={20}>
            <Heading
              minWidth={!isMobile ? 200 : '100%'}
              marginBottom={isMobile ? 15 : 0}
              fontSize={17}
              fontWeight={700}
              color={colors.gray800}>
              Results
            </Heading>
          </Pane>
          <Pane width="100%" paddingTop={20} paddingX={40}>
            {user.impacts.map((impact) => (
              <ActivistResult result={impact} />
            ))}
          </Pane>
        </Pane>
      )}
    </Card>
  )
}

export default ActivistImpacts
