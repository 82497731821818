import { useEffect } from 'react'
import { useGlobalState } from './use-global-state'
import isMobileDevice from '../utils/is-mobile-device'

function getDeviceType() {
  const isMobile = isMobileDevice()
  const { innerWidth: width, innerHeight: height } = window
  return {
    isDesktop: !isMobile && width >= 980,
    isTablet: !isMobile && width > 500 && width < 980,
    isMobile: isMobile || width <= 500,
    deviceDimensions: {
      width,
      height,
    },
  }
}

export default function useDeviceType() {
  const [/* deviceType */, setDeviceType] = useGlobalState('deviceType')

  useEffect(() => {
    setDeviceType(getDeviceType())

    function handleResize() {
      setDeviceType(getDeviceType())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setDeviceType])
}
