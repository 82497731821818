import { defaultTheme } from 'evergreen-ui'
import { merge } from 'lodash'

import colors from './colors'
import typography from './typography'
import Input from './input'
import Button from './button'
import Spinner from './spinner'
import Heading from './heading'

/*
  https://github.com/segmentio/evergreen/tree/29f9c41ff15527dac0892330b8032ccd585b1412/src/themes/default
*/

export default merge(
  defaultTheme,
  {
    colors,
    ...typography,
    radii: ['0px', '3px', '4px'],
    // fills,
    // intents,
    // shadows,
    // zIndices,

    components: {
      Button,
      Heading,
      Input,
      Spinner,

      //   Avatar: {
      //     /* ... */
      //   },
      //   Badge: {
      //     /* ... */
      //   },
      //   Card: {
      //     /* ... */
      //   },
      //   Checkbox: {
      //     /* ... */
      //   },
      //   Code: {
      //     /* ... */
      //   },
      //   DialogBody: {
      //     /* ... */
      //   },
      //   DialogFooter: {
      //     /* ... */
      //   },
      //   DialogHeader: {
      //     /* ... */
      //   },
      //   Group: {
      //     /* ... */
      //   },
      //   Heading: {
      //     /* ... */
      //   },
      //   Icon: {
      //     /* ... */
      //   },
      //   InlineAlert: {
      //     /* ... */
      //   },

      //   Label: {
      //     /* ... */
      //   },
      //   List: {
      //     /* ... */
      //   },
      //   Link: {
      //     /* ... */
      //   },
      //   MenuItem: {
      //     /* ... */
      //   },
      //   Option: {
      //     /* ... */
      //   },
      //   Pane: {
      //     /* ... */
      //   },
      //   Paragraph: {
      //     /* ... */
      //   },
      //   Radio: {
      //     /* ... */
      //   },
      //   Select: {
      //     /* ... */
      //   },
      //   Spinner: {
      //     /* ... */
      //   },
      //   Switch: {
      //     /* ... */
      //   },
      //   Tab: {
      //     /* ... */
      //   },
      //   Table: {
      //     /* ... */
      //   },
      //   TableCell: {
      //     /* ... */
      //   },
      //   TableHead: {
      //     /* ... */
      //   },
      //   TableRow: {
      //     /* ... */
      //   },
      //   TagInput: {
      //     /* ... */
      //   },
      //   Text: {
      //     /* ... */
      //   },
      //   TextDropdownButton: {
      //     /* ... */
      //   },
      //   Tooltip: {
      //     /* ... */
      //   },
    },
  },
)
