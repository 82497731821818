import React from 'react'
import {
  Pane,
  Text,
  defaultTheme,
} from 'evergreen-ui'

import { getGlobalState } from '../../hooks/use-global-state'

const { colors } = defaultTheme

function UserBadges() {
  const { isMobile, badges } = getGlobalState('user')

  return (
    <Pane
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      marginTop="0"
      paddingX="8px"
      width="100%"
      height="auto"
      background={colors.brand3}
      style={{ borderRadius: '0px 0px 5px 5px' }}>
      {badges.length > 0
        && badges.map((badge) => (
          <div
            width="100%"
            style={{ justifyContent: 'center', padding: '6px 0', display: 'flex' }}>
            <Text
              display="flex"
              textAlign="center"
              fontWeight={700}
              fontSize={18}
              color={colors.brand2}>
              {`${badge?.title}`}
            </Text>
          </div>
        ))}
    </Pane>
  )
}

export default UserBadges
