import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { orderBy, cloneDeep } from 'lodash'
import {
  Pane,
  Heading,
  Table,
  Spinner,
  defaultTheme,
} from 'evergreen-ui'

import { getGlobalState } from '../../hooks/use-global-state'

const { colors } = defaultTheme

const ActivistRecruits = () => {
  const { isMobile } = getGlobalState('deviceType')
  const user = getGlobalState('user')

  const sortRecruits = (recruits) => (
    orderBy(
      recruits,
      ['recruitedAt'],
      ['desc'],
    ).map((r) => ({
      ...r,
      fullName: `${r.recruitFirstName} ${r.recruitLastName}`,
      recruitedAt: moment(r.recruitedAt).format('M/D/YYYY'),
    }))
  )

  const [filteredRecruits, setFilteredRecruits] = useState(
    sortRecruits(user.recruits),
  )
  const [isInitialLoading, setIsInitialLoading] = useState(!user.recruits.length)

  useEffect(() => {
    setFilteredRecruits(sortRecruits(user.recruits))
  }, [JSON.stringify(user.recruits)]) // eslint-disable-line

  const handleFilterList = (text) => {
    let filtered = cloneDeep(sortRecruits(user.recruits))
    if (text) {
      filtered = filtered.filter((r) => (
        r.fullName.toLowerCase().includes(text.toLowerCase())
      ))
    }
    setFilteredRecruits(filtered)
  }

  useEffect(() => {
    /* re-fetching from useFetchGlobalInterval after 10s to grab any
       recruits pulled in after logging in, which relies on an async
       api call that could take a bit longer to run */
    if (isInitialLoading) {
      setTimeout(() => setIsInitialLoading(false), 15000)
    }
  }, [isInitialLoading])

  return (
    <Pane
      display="flex"
      alignItems="start"
      flexDirection="column"
      elevation={2}
      width={isMobile ? 'auto' : 560}
      padding={20}
      height="auto"
      background={colors.gray50}>
      <Heading
        minWidth={!isMobile ? 200 : '100%'}
        marginBottom={isMobile ? 15 : 0}
        paddingBottom={20}
        fontSize={17}
        fontWeight={700}
        color={colors.gray800}>
        Your Recruits
      </Heading>

      <Pane
        display="flex"
        justifyContent="center"
        width="100%"
        paddingX="20px">
        <Table
          width="100%">
          {!!user.recruits.length && (
            <Table.Head>
              <Table.SearchHeaderCell
                width={isMobile ? '100%' : 'auto'}
                flexBasis={isMobile ? '100%' : 270}
                flexShrink={0}
                flexGrow={0}
                onChange={handleFilterList} />
              {!isMobile && (
                <>
                  <Table.TextHeaderCell flexBasis={70}>
                    Action Type
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    Action Date
                  </Table.TextHeaderCell>
                </>
              )}
            </Table.Head>
          )}
          <Table.Body>
            {!user.recruits.length && (
              <Table.TextCell>
                {isInitialLoading ? (
                  <Spinner marginX="auto" marginTop={30} size={60} />
                ) : (
                  <div style={{
                    marginTop: 30, marginLeft: 15, fontSize: 18, lineHeight: '18px',
                  }}>
                    <em>Check back shortly...</em>
                    <br />
                    <br />
                    Folks will show up here when
                    {isMobile ? <br /> : <span>&nbsp;</span>}
                    they use your custom links!
                  </div>
                )}

              </Table.TextCell>
            )}
            {filteredRecruits.map(({
              id, fullName, recruitType, recruitedAt,
            }) => (
              <Table.Row
                key={id}>
                <Table.TextCell
                  flexBasis={isMobile ? '100%' : 270}
                  flexShrink={0}
                  flexGrow={0}>
                  <b style={{ fontSize: 16 }}>{fullName}</b>
                  {isMobile && (
                    <>
                      <br />
                      <div style={{ marginBottom: 3 }} />
                      Became a
                      <b>{recruitType}</b>
                      on
                      <b>{recruitedAt}</b>
                    </>
                  )}
                </Table.TextCell>
                {!isMobile && (
                  <>
                    <Table.TextCell
                      flexBasis={70}
                      textProps={{ fontSize: 13 }}>
                      {recruitType}
                    </Table.TextCell>
                    <Table.TextCell
                      textProps={{ fontSize: 13 }}>
                      {recruitedAt}
                    </Table.TextCell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  )
}

export default ActivistRecruits
