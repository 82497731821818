import React, { useState, useEffect, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'

import { Dialog, Alert } from 'evergreen-ui'

import useInterval from '../../hooks/use-interval'

function ErrorDialog() {
  const goBackInSec = 10
  const navigate = useNavigate()

  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const [secElapsed, setSecElapsed] = useState(0)

  useInterval(() => setSecElapsed(secElapsed + 1), 1000)

  useEffect(() => {
    if (secElapsed >= goBackInSec) {
      goBack()
    }
  }, [goBack, secElapsed])

  return (
    <Dialog
      isShown
      intent="danger"
      confirmLabel="Got it, go back"
      onClose={() => goBack()}>
      <Alert
        intent="danger"
        title="There's been an error on the page">
        {'Don\'t worry, this wasn\'t caused by something you did! '
          + 'We\'ve been notified and are working to squash the bug. '
          + 'Thanks for your patience while we work out the kinks!'}
      </Alert>
      Going back in
      {' '}
      {goBackInSec - secElapsed}
      {' '}
      seconds...
    </Dialog>
  )
}

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={ErrorDialog}>
    {children}
  </Sentry.ErrorBoundary>
)

export default ErrorBoundary
