import qs from 'qs'
import handleBlobDownload from './handle-blob-download'

export default async (path, options) => {
  options = options || {}
  let { query, data } = options

  if (!data && options?.body) data = options.body

  if (query) path = `${path}?${qs.stringify(query)}`

  const method = options.method || options.type

  // let csrfToken = ''
  // try {
  //   csrfToken = document.querySelector(
  //     'meta[name="csrf-token"]',
  //   ).getAttribute('content')
  // } catch (err) {
  //   // rarely document might not be instantiated imediately, retry once
  //   setTimeout(() => {
  //     csrfToken = document.querySelector(
  //       'meta[name="csrf-token"]',
  //     ).getAttribute('content')
  //   }, 500)
  // }

  options = {
    method: (method) || 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // ...(csrfToken ? { 'CSRF-Token': csrfToken } : {}),
    },
  }

  if (data instanceof FormData) delete options.headers['Content-Type']

  if (method && !['get', 'delete'].includes(method)) {
    options.body = data instanceof FormData
      ? data
      : JSON.stringify(data) || ''
  }

  try {
    if (path.substr(0, 1) !== '/') path = `/${path}`

    path = `/api${path}`

    const res = await fetch(path, options)

    if (!res.ok) {
      if (res.status === 401) throw new Error('Invalid token')
      if (res.status === 404) throw new Error('Resource not found')
      if (res.status === 503) {
        throw new Error(
          'The request has timed out – please try again later.',
        )
      }

      let errMessage = await res.text()

      if ((['{', '[']).includes(errMessage.charAt(0))) {
        errMessage = JSON.parse(errMessage)
      }

      const errCause = errMessage.cause || errMessage.code
      if (errMessage.message) errMessage = errMessage.message

      if (process.env.NODE_ENV === 'development') {
        console.error(errMessage) // eslint-disable-line
      }

      throw new Error(
        res.status === 500 || !errMessage
          ? 'Server Error – please try again later'
          : errMessage,
        { cause: errCause },
      )
    } else {
      const contentType = res.headers.get('Content-Type')
      let fileName = res.headers.get('Content-Disposition')
      if (fileName) fileName = fileName.replace('attachment; filename="', '').replace('"', '')
      if ([
        'application/zip',
        'application/csv',
        'application/text',
      ].includes(contentType)) {
        const blob = await res.blob()
        return handleBlobDownload(blob, contentType, fileName)
      }

      const bodyText = await res.text()

      if (!(['{', '[']).includes(bodyText.charAt(0))) {
        return bodyText
      }

      return JSON.parse(bodyText)
    }
  } catch (err) {
    if (err.message.includes('Failed to fetch')) {
      const netErr = new Error(
        'Network error, check your connection and refresh!',
      )
      netErr.code = 'NONET'
      throw netErr
    }
    throw err
  }
}
